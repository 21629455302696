import { useState, useEffect, createRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, Container, Col } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { faHouseSignal, faHouseUser, faUserCog, faCog, faChevronDown, faUserShield, faFileLines, faRocketLaunch, faSackDollar, faUsers, faToiletPaper, faToolbox } from "@fortawesome/pro-light-svg-icons";
import { useSessionStore } from "../../Stores/SessionStore";
import { Permissions } from "../../Utilities/Permissions";

function SidebarNavItem({ text, to, icon, expanded, isChild, disabled }) {
    
    const [active, setActive] = useState(false);
    
    function getClass({ isActive }) {
        setActive(isActive);

		return [
			isActive ? "text-black" : "text-medium",
			"d-flex", 
			"align-items-center", 
			"text-decoration-none",
			"w-100", 
			"px-2", 
			"py-2"
		].join(" ");
	}

	return <Nav.Item className="">
                <NavLink to={ to } className={ getClass } disabled={disabled}>
                    {!isChild || (isChild && expanded) ? <FontAwesomeIcon size="sm" fixedWidth className={active ? "text-primary" : "text-black"} icon={ icon }/> : null}
                    {expanded && text && <span className="ms-2 sidebar">{ text }</span>}
                </NavLink>
			</Nav.Item>
}

function SidebarHeaderItem({ children, expanded, icon, text, numChildren }) {
    const location = useLocation();  
    
    const [childrenRefs, setChildrenRefs] = useState([]);

    let active = false;
    const activeChild = children.find(child => child?.props?.to === location.pathname);

    if (activeChild) {
        active = true;
    } if (!activeChild && children) {
        children.forEach(child => {
            if (child?.props?.children) {
                let childActive = child.props.children.find(grandchild => grandchild.props.to === location.pathname);
                if (childActive) active = true;
            }
        });
    }

    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
    });

    const [collapsed, setCollapsed] = useState(false);
    const collapsedStyle = {
        overflow: "hidden",
        // height: `${expanded ? numChildren * 45 : 0}px`, // TODO - temporary workaround until there is time
        height: numChildren ? `${expanded ? numChildren * 45 : 0}px` : `${expanded ? dimensions.height : 0}px`,
        transform: "scaleY(100%)",
        transition: "height 0.2s ease-in-out, transform 0.2s ease",
        transformOrigin: "center top"
    }
    const expandedStyle = {
        overflow: "hidden", 
        height: "0px",
        transform: "scaleY(0%)",
        transition: "height 0.2s ease-in-out, transform 0.2s ease",
        transformOrigin: "center top"
    }

    useEffect(() => {
        if (!children) return;

        if (children.length > 0) {
            setChildrenRefs(children.map(() => createRef()));
        } else {
            setChildrenRefs([createRef(children)]);
        }
    }, [children]);

    useEffect(() => {
        if (childrenRefs.length > 0) {
            let height = 0;
            childrenRefs.forEach(ref => {
                height += ref.current.offsetHeight;
            });
            setDimensions({ width: 0, height: height });
        }
    }, [childrenRefs]);

    useEffect(() => {
    }, [collapsed]);
    
    return 	<div onMouseEnter={() => setCollapsed(true)} onMouseLeave={() => setCollapsed(false)}>
                <div className={ "px-2 py-1" }>
                    <FontAwesomeIcon size="sm" fixedWidth className={active ? "text-primary" : "text-black"} icon={ icon }/>
                    {expanded && text && <span className="ms-2 text-medium sidebar">{ text }</span>}
                    {expanded && 
                        <FontAwesomeIcon fontSize={4} size="sm" fixedWidth className={"float-right mt-2 text-black"} icon={ faChevronDown } onClick={() => {setCollapsed(!collapsed)}}
                            style={collapsed ? {transform: "rotate(180deg)", transition: "transform 0.2s"} : {transform: "rotate(0deg)", transition: "transform 0.2s"}}
                        />
                    }
                </div>
                <div style={collapsed ? collapsedStyle : expandedStyle} className="d-flex flex-column ps-2">
                    {children && children.map((child, index) => {
                        return <div key={index} className="ps-2" ref={childrenRefs[index]}>
                            {child}
                        </div>
                    })}
                </div>
            </div>
}

export default function Sidebar() {
    const store = useSessionStore();

    const [expanded, setExpanded] = useState(false);

    function isAllowed(section) {
        
        let sectionPermissions = [];

        let allowed = false;

        if (Permissions[section] !== undefined) {

            for (let type in Permissions[section]) {
                for (let permission in Permissions[section][type]) {
                    sectionPermissions.push(Permissions[section][type][permission]);
                    let permissionValue = store.Session.User.Permissions.find(p => p.PermissionId === Permissions[section][type][permission]);
                    if (permissionValue && permissionValue.Allowed) {
                        allowed = true;
                    }
                }
            }
            
        }  
        return allowed;  
    }

	return 	<Col
                className={`${expanded ? "shadow" : "half-column"} position-absolute h-100 bg-light px-4 pt-3`}
                style={{ zIndex: 1000, width: "20%" }}
                onMouseEnter={() => setExpanded(true)}
                onMouseLeave={() => setExpanded(false)}
            >
                <Container className="h-100 p-0 flex-column w-100">
                    <Nav className="flex-column ps-0">
                        <SidebarNavItem to="/Dashboard" icon={ faRocketLaunch } expanded={expanded} text="Dashboard" disabled></SidebarNavItem>
                        {isAllowed("Address Management") && <SidebarNavItem to="/Addresses" icon={ faHouseSignal } expanded={expanded} text="Address Management"></SidebarNavItem>}
                        <SidebarNavItem to="/Customers" icon={ faHouseUser } expanded={expanded} text="Customer Management" disabled></SidebarNavItem>
                        <SidebarNavItem to="/Reporting" icon={ faFileLines } expanded={expanded} text="Reporting" disabled></SidebarNavItem>
                        <SidebarHeaderItem expanded={expanded} icon={ faCog } text="Settings" numChildren={6}>
                            {isAllowed("User Management") &&
                                <SidebarHeaderItem expanded={expanded} icon={ faUserCog } text="User Management">
                                    <SidebarNavItem to="/Users" icon={ faUsers } expanded={expanded} text="Users" isChild></SidebarNavItem>
                                    <SidebarNavItem to="/Roles" icon={ faToiletPaper } expanded={expanded} text="Roles" isChild></SidebarNavItem>
                                    <SidebarNavItem to="/Permissions" icon={ faUserShield } expanded={expanded} text="Permissions Matrix" isChild></SidebarNavItem>
                                </SidebarHeaderItem>
                            }
                            <SidebarNavItem to="/Configuration" icon={ faToolbox } expanded={expanded} text="Configuration Management" isChild disabled></SidebarNavItem>
                            <SidebarNavItem to="/Packages" icon={ faSackDollar } expanded={expanded} text="Package Management" isChild disabled></SidebarNavItem>
                        </SidebarHeaderItem>
                    </Nav>
                </Container>
            </Col>
}