import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import PageContainer from "../../../Core/PageContainer";
import Panel from "../../../Core/Panel";
import Actions from "../../Common/Components/Actions";
import RoleModal from "../Details/RoleModal";
import ISODate from "../../../Core/ISODate";
import Headerbar from "./Headerbar";
import ConfirmModal from "../../../Core/ConfirmModal";
import AlertModal from "../../../Core/AlertModal";

export default function RolesIndex() {
    const store = useSessionStore();

    const [roles, setRoles] = useState(null);
    const [roleModal, setRoleModal] = useState(null);
    const [confirmModal, setConfirmModal] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        getRoles();
    }, [])

    async function getRoles() {
        
        let result = await store.UsersService.GetRoles();

        if (result) {
            setRoles(result.Data);
        }
    }

    async function deleteRole(id) {

        let result = await store.UsersService.DeleteRole(id);

        if (result && result.Success) {
            setSuccessMessage("Role deleted successfully");
            setShowAlert(true);
        } else {
            setAlertMessage("An error occurred while trying to delete the role.");
            setShowAlert(true);
        }
    }

    return <PageContainer>
                <Panel title="Roles">
                    <Headerbar
                        onSearchClicked={(search) => getRoles(search)}
                        onAddNewRole={() => {setRoleModal({show: true, role: null, onClose: (refresh) => {setRoleModal(null); if (refresh) {getRoles()}}, refresh: () => {getRoles()}})}}
                        >                            
                    </Headerbar>
                    <hr />
                    <Row>
                        <Col>
                            <Table striped borderless>
                                <thead>
                                    <tr>
                                        <th className="table-admin-header">User Role</th>
                                        <th className="table-admin-header">Date Created</th>
                                        <th className="table-admin-header">Created By</th>
                                        <th className="table-admin-header">Linked (Yes/No)</th>
                                        <th className="table-admin-header text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        roles &&
                                        roles.map((role, index) =>
                                            <tr key={role.Id}>
                                                <td className="d-none d-sm-table-cell td-admin">{role.Name}</td>
                                                <td className="d-none d-sm-table-cell td-admin"><ISODate date={role.DateCreated}/></td>
                                                <td className="d-none d-sm-table-cell td-admin">{`${role.CreatedByUser.FirstName} ${role.CreatedByUser.LastName}`}</td>
                                                <td className="d-none d-sm-table-cell td-admin">{role.Linked ? "Yes" : "No"}</td>
                                                <td className="text-center">
                                                    <Actions
                                                        edit={{
                                                            action: () => setRoleModal({show: true, role: role,
                                                            onClose: (refresh) => {setRoleModal(null); if (refresh) {getRoles()}},
                                                            refresh: () => {getRoles("")}}), title: "Edit Role"
                                                        }}
                                                        del={{
                                                            action: () => setConfirmModal({
                                                                show: true,
                                                                header: "Delete Role",
                                                                message: "Are you sure you want to delete this role?",
                                                                confirmAction: () => {deleteRole(role.Id); setConfirmModal(null)},
                                                                onClose: () => setConfirmModal(null)
                                                            }),
                                                            title: "Delete Role",
                                                            disabled: role.Linked
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Panel>
                {showAlert &&
                    <AlertModal                    
                        show={showAlert}
                        onClose={() => {
                            setShowAlert(false);
                            setAlertMessage("");
                            setSuccessMessage("");
                            getRoles();
                        }}
                        message={alertMessage ? alertMessage : successMessage}
                        success={successMessage}
                    />
                }
                {confirmModal && <ConfirmModal modal={confirmModal}/>}
                {roleModal && <RoleModal modal={roleModal}/>}
            </PageContainer>
}