import { Button as BSButton} from "react-bootstrap";
import { protectedComponent } from "../../Utilities/Permissions";

export default function Button({ onClick, children, permission }) {

    const button = (
        <BSButton className="btn w-100 btn-sm btn-admin-secondary" onClick={onClick}>{children}</BSButton>
    )

    return permission ? protectedComponent(permission, button) : button;
}