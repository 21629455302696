import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useSessionStore } from "../Stores/SessionStore";
import Main from "./Pages/Main";
import Auth from "./Pages/Authentication/Auth";

export default observer(function App() {
	const store = useSessionStore();

	useEffect(() => {
		//initialise the app
		if(store.Session) {
			store.loadUserPermissions();
		}

	}, [store.Session]);

	if (store.UserIsLoggedIn && store.Session?.User?.Permissions) {
		return <Main />
	} else {
		return <Auth />
	}
})