import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

export default function ConfirmModal({modal, ...props}) {

    return (
        <Modal
            {...props}
            show={modal.show}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="modal-admin-header">
                <Modal.Title id="contained-modal-title-vcenter">
                <div className="modal-admin-heading">{modal.header}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-4 pb-2">
                <div style={{fontSize: "11px"}}>
                    {modal.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-admin-primary"onClick={modal.confirmAction}>Yes</Button>
                <Button className="btn btn-admin-secondary"onClick={modal.onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}