import { Form as FormikForm, Formik, Field } from "formik";
import React, { useState } from "react";
import { Button, Card, Col, Row, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import * as yup from 'yup';
import { useEffect } from "react";

export default function ForgotPassword(props) {
	const navigate = useNavigate();

	const [requestingReset, setRequestingReset] = useState(false);
	const [requestSent, setRequestSent] = useState(false);

	const store = useSessionStore();
	const validationSchema =
		yup.object({
			Email: yup.string().required("Email is required.").email("Invalid Email Format.")
		}).required();

	async function handleFormSubmitClicked(values) {

		setRequestingReset(true);
		setRequestSent(false);
		
		var result = await store.UsersService.RequestPasswordReset(values.Email);
		setRequestingReset(false);

		if (result && result.Success) {
			setRequestSent(true);
		} else {
			setRequestSent(true);
		}
	}

	useEffect(() => {
		setRequestingReset(false);
		setRequestSent(false);

	}, [props.show]);


	return <Container size="lg" show={props.show}>
				<Row className="d-flex justify-content-center mt-5 pt-5">
					<Col lg={6}>
						<Card>
							<Card.Header>
								<h5>Reset Password</h5>
							</Card.Header>
							<Formik validationSchema={validationSchema} initialValues={{ Email: "" }} onSubmit={handleFormSubmitClicked}>
								{({ values, errors, touched }) => (
									<FormikForm>
										<Card.Body className="">
											{
													requestSent === false &&
													<>
														<p>Please enter the email address associated with your account. <br />We'll send an email containing a link for you to reset your password.</p>
														<Form.Group as={Row}>
															<Col className="col-12">
																<Form.Label>Email Address</Form.Label>
															</Col>
															<Col className="col-12">
																<Field isInvalid={touched.Email && errors.Email } busy={ requestingReset } name="Email" label="Email Address" className="w-100"/>
															</Col>
														</Form.Group>
														{
															touched.Email && errors.Email &&
															<small className="text-danger">{errors.Email}</small>
														}
													</>
												}
												{
													requestSent && 
													<p>If the provided email address is registered with us, you should receieve an email containing a link to reset your password. <br />
														Check your spam folder if you don't receieve an email within the hour.</p>
												}
												</Card.Body>
												<Card.Footer className="d-flex justify-content-end">
													{
														requestSent === false &&
														<>
															<Button className="me-2 btn-admin-primary" type="submit" disabled={requestingReset}>OK</Button>
															<Button className="btn-admin-primary" disabled={requestingReset} onClick={ () => navigate("/")}>Cancel</Button>
														</>
													}
													{
														requestSent &&
														<>
															<Button className="btn-admin-primary" onClick={() => navigate("/")}>Close</Button>
														</>
													}
												</Card.Footer>
										</FormikForm>
									)}
								</Formik>
						</Card>	
					</Col>
				</Row>
			</Container>
}