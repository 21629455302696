import { HashRouter, Routes, Route } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import MultiFactorAuthentication from "./MultiFactorAuthentication";

export default function Auth() {
    return <HashRouter>
                <Routes>
                    <Route path="/" element={ <Login></Login> }></Route>
                    <Route path="RegistrationApproval/:customerId" element={ <Login></Login> }></Route>
                    <Route path="/ForgotPassword" element={ <ForgotPassword></ForgotPassword> }></Route>
                    <Route path="/ResetPassword/:id" element={ <ResetPassword></ResetPassword> }></Route>
                    <Route path="/MultiFactorAuthentication" element={ <MultiFactorAuthentication></MultiFactorAuthentication> }></Route>
                </Routes>
            </HashRouter>
}