import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Container } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import logo from "../../Images/logo-Z.png"
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

export default function PageContainer({ children }) {
	const store = useSessionStore();
	const navigate = useNavigate();

	return 	<Container fluid className="h-100 ps-0">
                <Row className="text-white p-2" style={{backgroundColor: "#009845"}}>                    
                    <Col className="col-5 d-flex align-items-center">
                                <img style={{ width: "50px" }} src={ logo }></img>
                        <h5 className="ps-3">
                            Zing Fibre - Admin Portal
                        </h5>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center pe-4">
                        <small className="me-3 td-admin">{`Hi, ${store.Session.User.Name}. Make today great!`}</small>
                        <Button onClick={ e => {store.Logout(); navigate("/")} } className="btn-logout">Logout</Button>
                    </Col>
                </Row>
				<Row className="h-100  bg-light">
                    {/* <Col className="half-column"> */}
                        <Sidebar />
                    {/* </Col> */}
					<div className="eleven-half-column overflow-scroll h-100 pb-3 offset-half">
						{ children }
					</div>
				</Row>
			</Container>
}