import { faEdit, faEye, faTrashAlt, faUserShield, faUserSlash, faUserCheck } from "@fortawesome/pro-regular-svg-icons";
import { Row, Col, Stack } from "react-bootstrap";
import ClickIcon from "../../../Core/ClickIcon";

export default function Actions({ edit, view, del, disable, permissions }) {

	return 	<>
				<Row className="d-flex justify-content-center">
					<Col xs={8} className="d-flex justify-content-center align-items-center">
						<Stack gap={2} direction="horizontal">
							{edit && <ClickIcon onClick={() => edit.action()} icon={ faEdit } title={edit.title} {...(edit.permission && ({ permission: edit.permission }))}/>}
							{view && <ClickIcon onClick={ () => view.action()} icon={ faEye } title={view.title} {...(view.permission && ({ permission: view.permission }))}/>}
							{del && <ClickIcon onClick={ () => del.action()} icon={ faTrashAlt } title={del.title} disabled={del.disabled} {...(del.permission && ({ permission: del.permission }))}/>}
							{permissions && <ClickIcon onClick={ () => permissions.action()} icon={ faUserShield } title={permissions.title} {...(permissions.permission && ({ permission: permissions.permission }))}/>}
							{disable && <ClickIcon onClick={ () => disable.action()} icon={ disable.disabled ? faUserCheck : faUserSlash } title={disable.title} {...(disable.permission && ({ permission: disable.permission }))}/>}
						</Stack>
					</Col>
				</Row>
			</>
}