import { useState, useEffect } from "react";
import { Form, Container, Card, Row, Col, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";

export default function MultiFactorAuthentication() {
	const store = useSessionStore();
	const navigate = useNavigate();
    const location = useLocation();
    
    const [code, setCode] = useState(["", "", "", "", "", ""]);
    const [errorMessage, setErrorMessage] = useState("");

    const setup = location.state.setup;

     useEffect(() => {
        document.getElementById("mfa-input-0").focus();
    }, []);

    function handleChange(e, index) {

        if (isNaN(e.target.value)) {
            return;
        }

        if (errorMessage) setErrorMessage("");

        let newCode = [...code];
        newCode[index] = e.target.value;
        setCode(newCode);

        newCode = newCode.join("");

        if (newCode.length === 6) {
            validateCode(newCode);
        } else if (e.target.value.length === 1) {
            let nextInput = e.target.nextElementSibling;
            if (nextInput) {
                nextInput.focus();
            }
        } else if (e.target.value.length === 0) {
            let previousInput = e.target.previousElementSibling;
            if (previousInput) {
                previousInput.focus();
            }
        }
    }	

    async function validateCode(code) {
        let result = setup ? await store.UsersService.SetupMFA(location.state.userId, location.state.TwoFactorKey, code) : await store.Login(location.state.username, location.state.password, code);
        
        if (result && result.Success) {
            if (result.Data) navigate("/");
        } else {
            setErrorMessage(result.Message);
            setCode(["", "", "", "", "", ""]);
            document.getElementById("mfa-input-0").focus();
        }
    }

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('Text');
        
        if (pasteData.length === 6 && /^\d+$/.test(pasteData)) {
            const newValues = pasteData.split('');
            setCode(newValues);  
            validateCode(pasteData);
            e.preventDefault();
        }
    };

	return 	<Container>
				<Row className="d-flex justify-content-center mt-5 pt-5">
					<Col lg={6}>
						<Card>
							<Card.Header>
								<h5 className="text-center">
                                    {setup ? "Setup Multi-Factor Authentication" : "Zing Fibre Admin Portal MFA"}
                                </h5>
							</Card.Header>
							<Card.Body className="text-center">
								<Form>
									{setup ?
                                        <>
                                            <div className="td-admin w-75 mx-auto">
                                                <p>You are required to use MFA to access this application. Please scan the QR code with your authenticator app.</p>
                                                <p>After scanning the QR code, once you have the 6-digit code, enter it in the MFA code input</p>
                                            </div>
                                            <img src={location.state.QRCodeImageData} alt="QR Code" className="w-50"/>
                                            <Form.Text className="">Manual Key</Form.Text>
                                            <Form.Text className=""><small>(Enter this into your authenticator app if you cannot scan the QR code)</small></Form.Text>
                                            <Form.Text className="td-admin">{location.state.ManualEntryKey}</Form.Text>
                                        </> :
                                        <div className="td-admin">
                                            <p>Please enter the MFA code from your authenticator application.</p>
                                        </div>
                                    }
                                    <InputGroup className="w-50 mx-auto mt-3">
                                        <Form.Control 
                                            maxLength={"1"}
                                            value={code[0]}
                                            onChange={ e => handleChange(e, 0)}
                                            id="mfa-input-0"
                                            style={{borderBottomLeftRadius: "30%", borderTopLeftRadius: "30%"}}
                                            onPaste={handlePaste}                                                
                                        />
                                        <Form.Control maxLength={"1"} value={code[1]} onChange={ e => handleChange(e, 1)} />
                                        <Form.Control maxLength={"1"} value={code[2]} onChange={ e => handleChange(e, 2)} />
                                        <Form.Control maxLength={"1"} value={code[3]} onChange={ e => handleChange(e, 3)} />
                                        <Form.Control maxLength={"1"} value={code[4]} onChange={ e => handleChange(e, 4)} />
                                        <Form.Control 
                                            maxLength={"1"}
                                            value={code[5]}
                                            onChange={ e => handleChange(e, 5)}
                                            style={{borderBottomRightRadius: "30%", borderTopRightRadius: "30%"}}                                                
                                        />
                                    </InputGroup>
								</Form>
                                {
                                    errorMessage &&
                                    <small className="text-danger">{errorMessage}</small>
                                }
                                {setup && <Form.Text className="text-danger">When you are redirected to the login screen, please click on "I Forgot My Password" to set your password.</Form.Text>}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
}