import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import SearchField from "../../../Core/SearchField";
import Button from "../../../Core/Button";

export default function Headerbar({ onAddNewUser, handleSearch }) {

	const [search, setSearch] = useState("");

	return 	<Row>
				<Col className="col-12 col-lg-4 mb-3 mb-lg-0">
					<SearchField label="Search" name="search" value={search} onChange={(e) => {setSearch(e.target.value)}} onClick={() => handleSearch(search)} disabled={false} />
				</Col>				
				<Col className="col-12 col-lg-3 col-xl-2 col-xxl-2 offset-lg-6">
					<Button className="btn w-100 btn-sm btn-admin-secondary" onClick={onAddNewUser} permission={"User Management.Task Buttons.Add New User"}>Add New User</Button>
				</Col>
			</Row>
}