import { useEffect, useState } from "react";
import { Col, Row, Table, Form } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import PageContainer from "../../../Core/PageContainer";
import Panel from "../../../Core/Panel";
import Headerbar from "../Details/Headerbar";
import Actions from "../../Common/Components/Actions";
import UserModal from "../Details/UserModal";
import PermissionsModal from "../../Permissions/PermissionsModal";
import ConfirmModal from "../../../Core/ConfirmModal";
import AlertModal from "../../../Core/AlertModal";

export default function Users() {
    const store = useSessionStore();

    const [users, setUsers] = useState(null);
    const [userModal, setUserModal] = useState(null);
    const [permissionsModal, setPermissionsModal] = useState(null);
    const [confirmModal, setConfirmModal] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        getUsers("");
    }, [])

    async function getUsers(search) {
        setUsers(null);

        let result = await store.UsersService.GetUsers(0, 200, search);

        if (result && result.Success) {
            setUsers(result.Data);
        }
    }

    async function setUserActive(user, active) {

        let result = await store.UsersService.UpdateUser({...user, Active: active});

        if (result && result.Success) {
            setSuccessMessage(`User ${active ? "enabled" : "disabled"} successfully`);
            setShowAlert(true);
        } else {
            setAlertMessage(result && result.Message ? result.Message : `An error occurred while trying to ${active ? "enable" : "disable"} the user.`);
            setShowAlert(true);
        }
    }

    return <PageContainer>
                <Panel title="Users">
                    <Headerbar
                        handleSearch={(search) => getUsers(search)}
                        onAddNewUser={() => {setUserModal({show: true, id: null, onClose: () => {setUserModal(null)}, refresh: () => {getUsers("")}})}}
                        >                            
                    </Headerbar>
                    <hr />
                    <Row>
                        <Col>
                            <Table striped borderless>
                                <thead>
                                    <tr>
                                        <th className="table-admin-header">First Name</th>
                                        <th className="table-admin-header">Last Name</th>
                                        <th className="table-admin-header">Email Address</th>
                                        <th className="table-admin-header">Role</th>
                                        <th className="table-admin-header text-center">Active</th>
                                        <th className="table-admin-header text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users &&
                                        users.map((user, index) =>
                                            <tr key={user.Id}>
                                                <td className="d-none d-sm-table-cell td-admin">{user.FirstName}</td>
                                                <td className="d-none d-sm-table-cell td-admin">{user.LastName}</td>
                                                <td className="d-none d-sm-table-cell td-admin">{user.Email}</td>
                                                <td className="d-none d-sm-table-cell td-admin">{user.RoleName}</td>
                                                <td  className="d-none d-sm-table-cell td-admin text-center">
                                                    <Form.Check.Input  type="checkbox" checked={user.Active} onChange={ e => {} } className="position-relative ms-0" readOnly/>
                                                </td>
                                                <td align="center">
                                                    <Actions
                                                        edit={{
                                                            action: () => setUserModal({
                                                                show: true,
                                                                id: user.Id,
                                                                onClose: () => {setUserModal(null)},
                                                                refresh: () => {getUsers("")}
                                                            }),
                                                            title: "Edit User",
                                                            permission: "User Management.Actions.Edit User"
                                                        }}
                                                        permissions={{
                                                            action: () => setPermissionsModal({
                                                                show: true,
                                                                user: user,
                                                                onClose: () => {setPermissionsModal(null)},
                                                                refresh: () => {getUsers("")}
                                                            }),
                                                            title: "Edit Permissions",
                                                            permission: "User Management.Actions.User Permissions"
                                                        }}
                                                        disable={{
                                                            action: () => setConfirmModal({
                                                                show: true,
                                                                header: !user.Active ? "Enable User" : "Disable User",
                                                                message: !user.Active ? "Are you sure you want to enable this user?" : "Are you sure you want to disable this user?",
                                                                confirmAction: () => {setUserActive(user, !user.Active); setConfirmModal(null)},
                                                                onClose: () => setConfirmModal(null)
                                                            }),
                                                            title: !user.Active ? "Enable User" : "Disable User",
                                                            disabled: !user.Active,
                                                            permission: "User Management.Actions.Disable User"
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Panel>
                {showAlert &&
                    <AlertModal                    
                        show={showAlert}
                        onClose={() => {
                            setShowAlert(false);
                            setAlertMessage("");
                            setSuccessMessage("");
                            getUsers("");
                        }}
                        message={alertMessage ? alertMessage : successMessage}
                        success={successMessage}
                    />
                }
                {confirmModal && <ConfirmModal modal={confirmModal}/>}
                {userModal && <UserModal modal={userModal}/>}
                {permissionsModal && <PermissionsModal modal={permissionsModal}/>}
            </PageContainer>
}