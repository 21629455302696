import { Card } from "react-bootstrap";

export default function Panel({ children, title }) {
	return 	<Card  className="shadow pt-3 px-0 mb-5">
			{
				title &&
				<Card.Header>
					<h4 className="m-0 py-1">{title}</h4>
				</Card.Header>
			}
				<Card.Body>
					{children}
				</Card.Body>
			</Card>
}