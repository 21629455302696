import { useState, useEffect } from "react";
import { Col, Row, Form, Table } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import { useSessionStore } from "../../../Stores/SessionStore";
import PageContainer from "../../Core/PageContainer";
import Panel from "../../Core/Panel";
import AlertModal from "../../Core/AlertModal";

export default function PermissionsMatrix() {
    const store = useSessionStore();

    const [saving, setSaving] = useState(false);

    const [roles, setRoles] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    
    useEffect(() => {
        getRoles();
    }, [])

    function getSectionHeader(section, index, values, setFieldValue) {
        let firstIndex = permissions.findIndex(permission => permission.Section === section);
		if (index === firstIndex) {
			return <tr>
						<td className="td-admin border-bottom">
                            <strong className="text-info">{ section } (full access)</strong>
                        </td>
                        {roles &&
                            roles.map((role, index) =>
                                <td key={role.Id} className="d-none d-sm-table-cell td-admin text-center border-bottom">
                                    <Form.Check
                                        type="switch"
                                        checked={isSectionHeaderChecked(section, values, role.Id)}
                                        onChange={ e => {
                                            let sectionPermissions = permissions.filter(p => p.Section === section);
                                            sectionPermissions.forEach(permission => {
                                                handlePermissionChecked(permission, values, role.Id, setFieldValue, e.target.checked);
                                            });
                                        }}                                            
                                    />
                                </td>
                            )
                        }
					</tr>
		}
		return null;
	}

    function getTypeHeader(type, section, index, values, setFieldValue) {
        let firstIndex = permissions.findIndex(permission => permission.Type === type && permission.Section === section);
		if (index === firstIndex) {
			return <tr>
						<td className="td-admin ps-2">
                            <strong>{ type }</strong>
                        </td>
                        {roles &&
                            roles.map((role, index) =>
                                <td key={role.Id} className="d-none d-sm-table-cell td-admin text-center border-bottom">
                                    <Form.Check
                                        type="switch"
                                        checked={isTypeHeaderChecked(type, section, values, role.Id)}
                                        onChange={ e => {
                                            let typePermissions = permissions.filter(p => p.Type === type && p.Section === section);
                                            typePermissions.forEach(permission => {
                                                handlePermissionChecked(permission, values, role.Id, setFieldValue, e.target.checked);
                                            });
                                        }}                                         
                                    />
                                </td>
                            )
                        }
					</tr>
		}
		return null;
	}

    useEffect(() => {
        if (roles) getPermissionsTest();
        
    }, [roles])

    async function getPermissionsTest() {

        let result = await store.PermissionsService.GetPermissions();

        if (result && result.Success) {
            setPermissions(result.Data);
        }
    }

    async function getRoles() {
        let result = await store.PermissionsService.GetRolePermissions();

        if (result && result.Success) {
            setRoles(result.Data);
        }
    }

    function handlePermissionChecked(permission, values, roleId, setFieldValue, checked) {
        let role = values.find(r => r.Id === roleId);
        let setPermission = role.Permissions.find(p => p.PermissionId === permission.Id);

        if(setPermission) {
            setPermission.Allowed = checked;
        } else {
            role.Permissions.push({
                RoleId: roleId,
                PermissionId: permission.Id,
                Allowed: true,
                Id: null
            });
        }

        setFieldValue("Roles", values);
    }

    function isPermissionChecked(permission, values, roleId) {
        let role = values.find(r => r.Id === roleId);
		let setPermission = role.Permissions.find(p => p.PermissionId === permission.Id);

		if(setPermission && setPermission.Allowed) {
			return true;
		}

		return false;
	}

    function isSectionHeaderChecked(section, values, roleId) {
        let role = values.find(r => r.Id === roleId);
        let sectionPermissions = permissions.filter(p => p.Section === section);
        let checked = true;

        sectionPermissions.forEach(permission => {
            let setPermission = role.Permissions.find(p => p.PermissionId === permission.Id);

            if(!setPermission || !setPermission.Allowed) {
                checked = false;
            }
        });

        return checked;
    }

    function isTypeHeaderChecked(type, section, values, roleId) {
        let role = values.find(r => r.Id === roleId);
        let typePermissions = permissions.filter(p => p.Type === type && p.Section === section);
        let checked = true;

        typePermissions.forEach(permission => {
            let setPermission = role.Permissions.find(p => p.PermissionId === permission.Id);

            if(!setPermission || !setPermission.Allowed) {
                checked = false;
            }
        });

        return checked;
    }

    async function handleSubmit(values) {
        setSaving(true);

        let updatedPermissions = [];
        values.forEach(role => {
            role.Permissions.forEach(permission => {
                updatedPermissions.push(permission);
            });
        });

        let result = await store.PermissionsService.UpdateRolePermissions(updatedPermissions);

        setSaving(false);

        if (result && result.Success) {
            setSuccessMessage("Permissions saved successfully");
            setShowAlert(true);
        } else {
            setAlertMessage(result && result.Message ? result.Message : "An error occurred while updating the permissions");
            setShowAlert(true);
        }

    }

    return <PageContainer>
                <Panel title="Permissions Matrix">
                    <Formik initialValues={roles} validationSchema={null} onSubmit={handleSubmit} enableReinitialize={true} validateOnMount>
                        {({ errors, touched, values, setFieldValue }) => (
                            <FormikForm>
                                    {permissions &&
                                        <Table size="sm" striped borderless>
                                        <thead>
                                            <tr>
                                                <th className="" style={{fontSize: "11px", width: "17%"}}><b>Section</b></th>
                                                {
                                                    roles &&
                                                    roles.map((role, index) =>
                                                        <th key={role.Id} className="text-center" style={{fontSize: "11px"}}><b>{role.Name}</b></th>
                                                    )
                                                }								
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {permissions.map((permission, index) => 
                                                    <>
                                                        {getSectionHeader(permission.Section, index, values, setFieldValue)}
                                                        {getTypeHeader(permission.Type, permission.Section, index, values, setFieldValue)}
                                                        <tr key={permission.Id}>
                                                            <td className="td-admin ps-3">{permission.Name}</td>
                                                            {roles &&
                                                                roles.map((role, index) =>
                                                                    <td key={role.Id} className="td-admin text-center border-bottom">
                                                                        <Form.Check type="switch" onChange={ e => {handlePermissionChecked(permission, values, role.Id, setFieldValue, e.target.checked)} } checked={isPermissionChecked(permission, values, role.Id)}/>
                                                                    </td>
                                                                )
                                                            }
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        </tbody>
                                    </Table>}
                                    <Row>
                                        <Col className="col-1 offset-10">
                                            <button type="button" className="btn btn-admin-secondary w-100" onClick={() => {getRoles()}}>Cancel</button>
                                        </Col>
                                        <Col className="col-1">
                                            <button type="submit" className="btn btn-admin-primary w-100">{saving ? "Saving..." : "Save"}</button>
                                        </Col>
                                    </Row>
                            </FormikForm>
                        )}
                    </Formik>
                </Panel>
                <AlertModal                    
                    show={showAlert}
                    onClose={() => {
                        setShowAlert(false);
                        setAlertMessage("");
                        setSuccessMessage("");
                    }}
                    message={alertMessage ? alertMessage : successMessage}
                    success={successMessage}
                />
            </PageContainer>    
}