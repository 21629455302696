import PageContainer from "../../Core/PageContainer";
import Panel from "../../Core/Panel";

export default function UnderConstruction() {

    return <PageContainer>
                <Panel title="Under Construction">
                    <div className="text-center">
                        <h1 className="display-1">🚧😊</h1>
                        <h2>Coming Soon</h2>
                    </div>
                </Panel>
            </PageContainer>
}