import APIBase from "./APIBase";

/**
 * @typedef PermissionsService
 * @type {PermissionsService}
 */
export default class UsersService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Permissions", sessionStore);
    }

    /**
     * @returns {Promise<Object>} A promise that resolves to the list of permissions.
     */
    async GetPermissions() {
        return this.Get("GetPermissions");
    }

    /**
     * Fetches the permissions associated with a role.
     * 
     * @returns {Promise<any>} A promise that resolves to the role permissions.
     */
    async GetRolePermissions() {
        return this.Get("GetRolePermissions");
    }

    /**
     * Fetches permissions for a specific role.
     *
     * @param {string} roleId - The ID of the role to fetch permissions for.
     * @returns {Promise<any>} A promise that resolves to the permissions for the specified role.
     */
    async GetPermissionsForRole(roleId) {
        return this.Get(`GetPermissionsForRole?roleId=${encodeURIComponent(roleId)}`);
    }

    /**
     * Retrieves the permissions for a specific user.
     *
     * @param {string} userId - The ID of the user whose permissions are being retrieved.
     * @returns {Promise<Object>} A promise that resolves to the user's permissions.
     */
    async GetUserPermissions(userId) {
        return this.Get(`GetUserPermissions?userId=${encodeURIComponent(userId)}`);
    }

    /**
     * Updates the role permissions.
     *
     * @param {Object} permissions - The permissions object to update.
     * @returns {Promise} A promise that resolves with the response of the update operation.
     */
    async UpdateRolePermissions(permissions) {
        return this.Post("UpdateRolePermissions", permissions);
    }

    /**
     * Updates the permissions for a user.
     *
     * @param {Object} permissions - The permissions object to update.
     * @returns {Promise} A promise that resolves with the result of the update operation.
     */
    async UpdateUserPermissions(permissions) {
        return this.Post("UpdateUserPermissions", permissions);
    }
}