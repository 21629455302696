import APIBase from "./APIBase";

/**
 * @typedef User
 * @property {string} Id
 * @property {string} Name
 * @property {string} FirstName
 * @property {string} LastName
 * @property {string} Email
 * @property {string} Password
 * @property {string} ConfirmPassword
 */

/**
 * @typedef UsersService
 * @type {UsersService}
 */
export default class UsersService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Users", sessionStore);
    }

    /**
     * @param {string} email The email address of the user
     * @param {string} password The user's password
     * @returns { import("./APIBase").RequestResult<import("../Stores/SessionStore").Session> }
     */
    async Login(username, password, code) {
        return this.Post("login", { Username: username, Password: password, ValidationCode: code });
    }

    /**
     * @param {User} user The User object to create
     * @returns { Promise<import("./APIBase").RequestResult<string>> }
     */
    async CreateUser(user) {
        return this.Post("CreateUser", user);
    }

    /**
     * @param {User} user The User object to update
     */
    async UpdateUser(user) {
        return this.Post("UpdateUser", user);
    }

    /**
     * @param {string} userId The Guid of the user to delete
     */
    async DeleteUser(userId) {
        return this.Post("DeleteUser", userId);
    }

    /**
     * @param {number} startIndex The 0 based start page index
     * @param {number} pageCount The total amount of results to return for the page
     * @param {string} search String containing a string to filter results by
     * @returns { import("./APIBase").EnumerableRequestResult<User> }
     */
    async GetUsers(startIndex, pageCount, search) {
        return this.Get(`GetUsers?startIndex=${startIndex}&pageCount=${pageCount}&search=${encodeURIComponent(search)}`);
    }

    /**
     * @param {string} userId The Id of the user to retrieve
     * @returns { Promise<import("./APIBase").RequestResult<User>> }
     */
    async GetUser(userId) {
        return this.Get(`GetUser?userId=${userId}`);
    }

    /**
     * @param {User} emailAddress The User email address to request a password reset for
     */
    async RequestPasswordReset(emailAddress) {
        return this.Post(`RequestPasswordReset?emailAddress=${encodeURIComponent(emailAddress)}`);
    }

    /**
     * @param {User} resetRequestId The reset request Id
     * @param {User} newPassword The new password
     * @param {User} confirmPassword The new password confirmation
     */
    async ResetPassword(resetRequestId, newPassword, confirmPassword) {
        return this.Post(`ResetPassword?resetRequestId=${resetRequestId}&newPassword=${encodeURIComponent(newPassword)}&newPasswordConfirm=${encodeURIComponent(confirmPassword)}`);
    }

    /**
     * @returns {Promise<Object>} A promise that resolves to the list of roles.
     */
    async GetRoles() {
        return this.Get("GetRoles");
    }

    /**
     * @param {string} roleId - The ID of the role to retrieve.
     * @returns {Promise<Object>} A promise that resolves to the role information.
     */
    async GetRole(roleId) {
        return this.Get(`GetRole?roleId=${encodeURIComponent(roleId)}`);
    }

    /**
     * Creates a new role by sending a POST request.
     *
     * @param {Object} role - The role object to be created.
     * @returns {Promise} - A promise that resolves to the response of the POST request.
     */
    async CreateRole(role) {
        return this.Post("CreateRole", role);
    }

    /**
     * Updates the role of a user.
     *
     * @param {Object} role - The role object containing the updated role information.
     * @returns {Promise} A promise that resolves with the response of the update operation.
     */
    async UpdateRole(role) {
        return this.Post("UpdateRole", role);
    }

    /**
     * Deletes a role by its ID.
     *
     * @param {string} roleId - The ID of the role to delete.
     * @returns {Promise} A promise that resolves when the role is deleted.
     */
    async DeleteRole(roleId) {
        return this.Post(`DeleteRole?roleId=${encodeURIComponent(roleId)}`);
    }

    /**
     * Validates the login credentials of a user.
     *
     * @param {string} username - The username of the user.
     * @param {string} password - The password of the user.
     * @returns {Promise<any>} A promise that resolves with the response of the login validation.
     */
    async ValidateLogin(username, password) {
        return this.Post("ValidateLogin", { Username: username, Password: password });
    }

    /**
     * Retrieves the Multi-Factor Authentication (MFA) setup for a given user.
     *
     * @param {string} userId - The unique identifier of the user.
     * @returns {Promise<Object>} A promise that resolves to the MFA setup details of the user.
     */
    async GetMFASetup(userId) {
        return this.Get(`GetMFASetup?userId=${encodeURIComponent(userId)}`);
    }

    /**
     * Sets up Multi-Factor Authentication (MFA) for a user.
     *
     * @param {string} userId - The ID of the user.
     * @param {string} twoFactorKey - The key for two-factor authentication.
     * @param {string} validationCode - The validation code for setting up MFA.
     * @returns {Promise} A promise that resolves with the response of the POST request.
     */
    async SetupMFA(userId, twoFactorKey, validationCode) {
        return this.Post(`SetupMFA?userId=${encodeURIComponent(userId)}&twoFactorKey=${encodeURIComponent(twoFactorKey)}&validationCode=${encodeURIComponent(validationCode)}`);
    }
}