import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Form, Container, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";

const LoginModal = observer(function Login() {
	const navigate = useNavigate();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState("");

	const store = useSessionStore();

	async function handleLoginClicked() {

		let result = await store.UsersService.ValidateLogin(username, password);
		let userId = null;

		if((result && result.Success === false) || !result) {
			setLoginError(result.Message);
			return;
		}

		userId = result.Data.UserId;

		if (result.Data.TwoFactorKey) {
			
			navigate("/MultiFactorAuthentication", { state: { ...result.Data, username, password, setup: false } });
			return;
		}
		
		result = await store.UsersService.GetMFASetup(userId);

		if(result && result.Success) {
			
			navigate("/MultiFactorAuthentication", { state: { ...result.Data, userId: userId, setup: true } });
		}		
	}

	function handleKeypress(key) {
		if(key === "Enter") {
			handleLoginClicked();
		}
	}

	return 	<Container>
				<Row className="d-flex justify-content-center mt-5 pt-5">
					<Col lg={6}>
						<Card>
							<Card.Header>
								<h5>Sign In</h5>
							</Card.Header>
							<Card.Body>
								<Form>
									<Form.Group>
										<Form.Label>Username</Form.Label>
										<Form.Control value={username} onChange={ e => setUsername(e.target.value)} onKeyPress={ e => handleKeypress(e.code)}></Form.Control>
									</Form.Group>
									<Form.Group className="mt-3">
										<Form.Label>Password</Form.Label>
										<Form.Control type="password" value={password} onKeyPress={ e => handleKeypress(e.code)} onChange={ e => setPassword(e.target.value)}></Form.Control>
									</Form.Group>
								</Form>
								{
									loginError &&
									<small className="text-danger">{loginError}</small>
								}
								<div className="d-flex justify-content-end mt-3">
									<small onClick={ () => navigate("/ForgotPassword") } className="text-primary" style={{ cursor: "pointer" }}>I Forgot My Password</small>
								</div>
							</Card.Body>
							<Card.Footer className="d-flex justify-content-end">
								<Button className="btn-admin-primary" disabled={ store.LoggingIn } onClick={ handleLoginClicked }>Login</Button>
							</Card.Footer>
						</Card>
					</Col>
				</Row>
			</Container>
});

export default LoginModal;