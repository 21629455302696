import ProtectedComponent from "../Components/Core/ProtectedComponent";

export const Permissions = {
    "Address Management": {
        Columns: {
            "Township" : 1,
            "ERF" : 2,
            "Street Address" : 3,
            "MDU Name" : 4,
            "MDU Block" : 5,
            "MDU Unit Number" : 6,
            "W3W" : 7,
            "Property Type" : 8,
            "POP" : 9,
            "Build" : 10,
            "Install Date" : 11,
            "PPPoE Username" : 12,
            "PPPoE Password" : 13,
            "Active Package" : 14,
            "ONT Status" : 15,
            "PPPoE Status" : 16

        },
        Actions: {
            "Refresh Status" : 17,
            "Edit Address" : 18,
            "Install Complete" : 19

        },
        "Task Buttons": {
            "Add New Address" : 20,
            "Bulk Import" : 21,
            "Bulk Export" : 22

        },
    },
    "User Management": {
        "Task Buttons": {
            "Add New User": 23
        },
        Actions: {
            "Edit User" : 24,
            "User Permissions" : 25,
            "Disable User" : 26
        }
    }
}

export const protectedComponent = (permission, children) => {
    return (
        <ProtectedComponent permission={permission}>
            {children}
        </ProtectedComponent>
    )
}