import React from "react";
import { Permissions } from "../../Utilities/Permissions";
import { useSessionStore } from "../../Stores/SessionStore";

export default function ProtectedComponent({permission, children}) {
    const store = useSessionStore();

    if (!store.Session.User.Permissions) {
        return (
            <></>
        )
    }
    
    const permissionArray = permission.split(".");

    let permissionValue = store.Session.User.Permissions.find(p => p.PermissionId === Permissions[permissionArray[0]][permissionArray[1]][permissionArray[2]]);
    let allowed = false;

    if (permissionValue && permissionValue.Allowed) {
        allowed = true;
    }

    /* Code commented out in case this feature is required in the future */
    
    // function renderDisabledChildren(children) {
    //     return React.Children.map(children, child => {
    //         return React.cloneElement(child, {
    //             disabled: true
    //         })       
    //     })
    // }

    // if (!permissionValue) {
    //     return (
    //         <>
    //             {renderDisabledChildren(children)}
    //         </>
    //     )
    // }

    if (!allowed) {
        return (
            <></>
        )
    }

    return (
        <>
            {children}
        </>
    )
}
