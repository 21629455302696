import { useState, useEffect } from "react";
import { Form, Row, Col, Modal } from "react-bootstrap";
import { Field, Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../../Core/AlertModal";

export default function RoleModal({modal}) {
    const store = useSessionStore();
    const navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [saving, setSaving] = useState(false);

    const [details, setDetails] = useState({
        Id: null,
        Name: ""
    });

    useEffect(() => {
        if (modal.role && modal.role.Id) {
            setDetails({Id: modal.role.Id, Name: modal.role.Name});
        }
    }, [modal.role]);

    const handleSubmit = async (data) => {

        setSaving(true);
        
        if (modal.role && modal.role.Id) {

            const result = await store.UsersService.UpdateRole(data);

            if (result && result.Success) {
                setSuccessMessage("Role updated successfully");
                setShowAlert(true);
            } else {
                if (result && result.Message) {
                    setAlertMessage(result.Message);
                    setShowAlert(true);
                } else {
                    setAlertMessage("An error occurred while updating role");
                    setShowAlert(true);
                }
            }
        } else if (!modal.role || !modal.role.Id) {

            const result = await store.UsersService.CreateRole(data);

            if (result && result.Success) {
                setSuccessMessage(<><div>Role added successfully.</div><br /><div>You will now be directed to the Permissions Matrix.</div></>);
                setShowAlert(true);
            } else {
                if (result && result.Message) {
                    setAlertMessage(result.Message);
                    setShowAlert(true);
                } else {
                    setAlertMessage("An error occurred while adding role");
                    setShowAlert(true);
                }
            }
        }
       
        setSaving(false);
        return;
    }

    const validationSchema = yup.object().shape({		
        Name: yup.string().required("Role Name is required")
	}).required();

    return (
        <Modal show={ modal.show } dialogClassName="modal-install-complete">
            <Modal.Header className="modal-admin-header">
                <div className="modal-admin-heading">{`${modal.role && modal.role.Id ? "Edit" : "Add"} Role`}</div>
            </Modal.Header>
            <Formik initialValues={details} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true} validateOnMount>
                {({ errors, touched }) => (
                    <FormikForm>                                    
                        <Modal.Body>
                            <Row>
                                <Form.Group as={Row}>
                                    <Col className="col-4 d-flex align-items-center">
                                        <Form.Label>Role Name</Form.Label>
                                    </Col>
                                    <Col className="col-8">
                                        <Field isInvalid={ errors.Name } label="Role Name" name="Name"  as={Form.Control}></Field>
                                            {
                                                errors.Name  &&
                                                <small className="text-danger">{ errors.Name }</small>
                                            }
                                    </Col>
                                </Form.Group>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-admin-primary">{saving ? "Saving..." : "Save"}</button>
                            <button type="button" className="btn btn-admin-secondary" onClick={() => modal.onClose(false)}>Cancel</button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
            <AlertModal                    
                show={showAlert}
                onClose={() => {
                    setShowAlert(false);
                    setAlertMessage("");
                    setSuccessMessage("");
                    if (successMessage) {
                        modal.onClose(true);
                        navigate("/Permissions");
                    }}
                }
                message={alertMessage ? alertMessage : successMessage}
                success={successMessage}                            
            />
        </Modal>
    )
}