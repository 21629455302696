import { useEffect, useState } from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import RegistrationApproval from "./RegistrationApproval/RegistrationApproval";
import Addresses from "./Addresses/Index/Addresses";
import Users from "./Users/Index/Users";
import RolesIndex from "./Roles/Index/RolesIndex";
import PermissionsMatrix from "./Permissions/PermissionsMatrix";
import { Permissions } from "../../Utilities/Permissions";
import { useSessionStore } from "../../Stores/SessionStore";
import UnderConstruction from "../Pages/Dashboard/UnderConstruction";

export default function Main() {
    const store = useSessionStore();

    const [defaultRoute, setDefaultRoute] = useState("");

    useEffect(() => {
		getDefaultRoute();
	}, []);

    function getDefaultRoute() {
        let route = "";
        if (isAllowed("Address Management")) {
            route = "/Addresses";
        } else if (isAllowed("User Management")) {
            route = "/Users";
        } else {
            route = "/Dashboard";
        }
        setDefaultRoute(route);
    }

    function isAllowed(section) {
        
        let sectionPermissions = [];

        let allowed = false;

        if (Permissions[section] !== undefined) {

            for (let type in Permissions[section]) {
                for (let permission in Permissions[section][type]) {
                    sectionPermissions.push(Permissions[section][type][permission]);
                    let permissionValue = store.Session.User.Permissions.find(p => p.PermissionId === Permissions[section][type][permission]);
                    if (permissionValue && permissionValue.Allowed) {
                        allowed = true;
                    }
                }
            }
            
        }  
        return allowed;  
    }

    return <HashRouter>
                <Routes>
                    <Route path="RegistrationApproval/:customerId" element={ <RegistrationApproval></RegistrationApproval> }></Route>
                    <Route path="/Dashboard" element={ <UnderConstruction></UnderConstruction> }></Route>
                    <Route path="/Customers" element={ <UnderConstruction></UnderConstruction> }></Route>
                    <Route path="/Reporting" element={ <UnderConstruction></UnderConstruction> }></Route>
                    <Route path="/Configuration" element={ <UnderConstruction></UnderConstruction> }></Route>
                    <Route path="/Packages" element={ <UnderConstruction></UnderConstruction> }></Route>
                    {isAllowed("Address Management") &&
                        <Route path="/Addresses" element={ <Addresses></Addresses> }></Route>
                    }
                    {isAllowed("User Management") &&
                        <>
                            <Route path="/Users" element={ <Users></Users> }></Route>
                            <Route path="/Roles" element={ <RolesIndex></RolesIndex> }></Route>
                            <Route path="/Permissions" element={ <PermissionsMatrix></PermissionsMatrix> }></Route>
                        </>
                    }
                    {defaultRoute && <Route path="*" element={ <Navigate to={defaultRoute} /> }></Route>}
                </Routes>
            </HashRouter>
}