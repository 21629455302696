import { useState, useEffect } from "react";
import { Modal, Form, Table } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import { useSessionStore } from "../../../Stores/SessionStore";
import AlertModal from "../../Core/AlertModal";

export default function PermissionsModal({modal}) {
    const store = useSessionStore();

    const [saving, setSaving] = useState(false);

    const [user, setUser] = useState({});
    const [permissions, setPermissions] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        getPermissions();
    }, [])

    useEffect(() => {
        if(permissions) {
            getUserPermissions();
        }
    }, [permissions])

    async function getPermissions() {

        let result = await store.PermissionsService.GetPermissions();

        if (result && result.Success) {
            setPermissions(result.Data);
        }
    }

    async function getUserPermissions() {
        
        let result = await store.PermissionsService.GetUserPermissions(modal.user.Id);

        if (result && result.Success) {
            
            setUser({Permissions: result.Data});
        }
    }

    function getSectionHeader(section, index, values, setFieldValue) {
        let firstIndex = permissions.findIndex(permission => permission.Section === section);
		if (index === firstIndex) {
			return <tr>
                <td className="td-admin border-bottom">
                    <strong className="text-info">{ section } (full access)</strong>
                </td>
                <td className="d-none d-sm-table-cell td-admin text-center">
                    <Form.Check type="switch" checked={isSectionHeaderChecked(section, values)} onChange={ e => {
                        let sectionPermissions = permissions.filter(p => p.Section === section);
                        
                        sectionPermissions.forEach(permission => {
                            handlePermissionChecked(permission, values, setFieldValue, e.target.checked);
                        });
                    } } />
                </td>
            </tr>
		}
		return null;
	}

    function getTypeHeader(type, section, index, values, setFieldValue) {
        let firstIndex = permissions.findIndex(permission => permission.Type === type && permission.Section === section);
		if (index === firstIndex) {
			return <tr>
						<td className="td-admin ps-2">
                            <strong>{ type }</strong>
                        </td>
                        <td className="d-none d-sm-table-cell td-admin text-center">
                            <Form.Check type="switch" checked={isTypeHeaderChecked(type, section, values)} onChange={ e => {
                                let typePermissions = permissions.filter(p => p.Type === type && p.Section === section);
                                typePermissions.forEach(permission => {
                                    handlePermissionChecked(permission, values, setFieldValue, e.target.checked);
                                });
                            } } />
                        </td>
					</tr>
		}
		return null;
	}

    function handlePermissionChecked(permission, values, setFieldValue, checked) {
        
        let setPermission = values.Permissions.find(p => p.PermissionId === permission.Id);

        if(setPermission) {
            setPermission.Allowed = checked;
        } else {
            user.Permissions.push({
                UserId: modal.user.Id,
                PermissionId: permission.Id,
                Allowed: true,
                Id: null
            });
        }

        setFieldValue("UserPermissions", values);
    }

    function isPermissionChecked(permission, values) {
		let setPermission = user?.Permissions?.find(p => p.PermissionId === permission.Id);

		if(setPermission && setPermission.Allowed) {
			return true;
		}

		return false;
	}

    function isSectionHeaderChecked(section, values) {
        let sectionPermissions = permissions.filter(p => p.Section === section);
        let checked = true;

        sectionPermissions.forEach(permission => {
            let setPermission = values?.Permissions?.find(p => p.PermissionId === permission.Id);

            if(!setPermission || !setPermission.Allowed) {
                checked = false;
            }
        });

        return checked;
    }

    function isTypeHeaderChecked(type, section, values) {
        let typePermissions = permissions.filter(p => p.Type === type && p.Section === section);
        let checked = true;

        typePermissions.forEach(permission => {
            let setPermission = values?.Permissions?.find(p => p.PermissionId === permission.Id);

            if(!setPermission || !setPermission.Allowed) {
                checked = false;
            }
        });

        return checked;
    }

    async function handleSubmit(values) {
        
        setSaving(true);

        let result = await store.PermissionsService.UpdateUserPermissions(values.Permissions);

        setSaving(false);

		if (result && result.Success) {
            setSuccessMessage("User saved successfully");
            setShowAlert(true);
        } else {
            setAlertMessage(result && result.Message ? result.Message : "An error occurred while updating the user");
            setShowAlert(true);
        }
    }

    async function handleDefaultClicked() {

        let result = await store.PermissionsService.GetPermissionsForRole(modal.user.RoleId);

        if (result && result.Success) {
            
            let rolePermissions = [...result.Data];
            let userPermissions = [...user.Permissions];

            userPermissions.forEach((userPermission) => {
                userPermission.Allowed = false;
            });

            rolePermissions.forEach((rolePermission) => {
                let userPermission = userPermissions.find(p => p.PermissionId === rolePermission.PermissionId);
                if (userPermission) {
                    userPermission.Allowed = rolePermission.Allowed;
                } else {
                    userPermissions.push(rolePermission);
                }
            });
            
            setUser({Permissions: userPermissions});
        }
    }

    return (
        <Modal show={ modal.show } >
            <Modal.Header className="modal-admin-header">
                <div className="modal-admin-heading">Permissions Matrix</div>
            </Modal.Header>
            <Formik initialValues={user} validationSchema={null} onSubmit={handleSubmit} enableReinitialize={true} validateOnMount>
                {({ values, setFieldValue }) => (
                    <FormikForm>
                        <Modal.Body>
                            {permissions &&
                                <Table size="sm" striped borderless>
                                    <thead>
                                        <tr>
                                            <th colSpan={3} className="text-center" style={{fontSize: "11px"}}><b>{`${modal.user.FirstName} ${modal.user.LastName}`}</b></th>
                                        </tr>
                                        <tr>
                                            <td className="td-admin text-end align-middle">{`Reset permissions to ${modal.user.RoleName} default`}</td>
                                            <td className="td-admin text-center">
                                                <button type="button" className="btn btn-admin-primary" onClick={ handleDefaultClicked }>Reset</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="" style={{fontSize: "11px"}}><b>Section</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {permissions.map((permission, index) => 
                                                <>
                                                    {getSectionHeader(permission.Section, index, values, setFieldValue)}
                                                    {getTypeHeader(permission.Type, permission.Section, index, values, setFieldValue)}
                                                    <tr key={permission.Id}>
                                                        <td className="td-admin ps-3">{permission.Name}</td>
                                                        <td className="td-admin text-center">
                                                            <Form.Check type="switch" onChange={ e => {handlePermissionChecked(permission, values, setFieldValue, e.target.checked)} } checked={isPermissionChecked(permission, values)}/>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-admin-primary">{saving ? "Saving..." : "Save"}</button>
                            <button type="button" className="btn btn-admin-secondary" onClick={() => {modal.onClose(false)}}>Cancel</button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
            <AlertModal                    
                show={showAlert}
                onClose={() => {
                    setShowAlert(false);
                    setAlertMessage("");
                    setSuccessMessage("");
                    modal.refresh();
                    modal.onClose(true);
                }}
                message={alertMessage ? alertMessage : successMessage}
                success={successMessage}
            />
        </Modal>
    )
}