import { useState, useEffect } from "react";
import { Form, Row, Col, Modal, Stack } from "react-bootstrap";
import { Field, Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { useSessionStore } from "../../../../Stores/SessionStore";
import AlertModal from "../../../Core/AlertModal";

export default function UserModal({modal}) {
    const store = useSessionStore();

	const [user, setUser] = useState({
        Id: null,
        Email: "",
        FirstName: "",
        LastName: "",
        RoleId: null,
        Active: true,
        Password: "changemenow",
        ConfirmPassword: "changemenow",
        TwoFactorKey: null
    });
    const [roles, setRoles] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        getRoles();
    }, []);

	useEffect(() => {
		if (roles && modal.id) {
			getUser();
		}
	}, [modal.id, roles]);
    
    async function handleSaveClicked(data) {
		let result = null;	

		setSaving(true);

		if(modal.id) {
			result = await store.UsersService.UpdateUser(data);
		} else {
			result = await store.UsersService.CreateUser(data);
		}

		setSaving(false);

		if (result && result.Success) {
            setSuccessMessage("User saved successfully");
            setShowAlert(true);
        } else {
            setAlertMessage(result && result.Message ? result.Message : "An error occurred while updating the user");
            setShowAlert(true);
        }
        setSaving(false);
        return;
	}

	async function getUser() {
		
		const result = await store.UsersService.GetUser(modal.id);

		if (result && result.Success) {
			setUser({
				Id: modal.id,
				Email: result.Data.Email,
				FirstName: result.Data.FirstName,
				LastName: result.Data.LastName,
                RoleId: result.Data.RoleId,
                Active: result.Data.Active,
                TwoFactorKey: result.Data.TwoFactorKey
			});			
		}	
	}

    async function getRoles() {
        let result = await store.UsersService.GetRoles();

        if (result) {
            setRoles(result.Data);
        }
    }

    const validationSchema = yup.object().shape({
        FirstName: yup.string().required("First Name is required"),
        LastName: yup.string().required("Last Name is required"),
        Email: yup.string().email("Invalid email address").required("Email Address is required"),
        RoleId: yup.string().required("Role is required").nullable()
    });

    return (
        <Modal show={ modal.show } dialogClassName="modal-address">
            <Modal.Header className="modal-admin-header">
                <div className="modal-admin-heading">{`${modal.user ? "Edit" : "Add"} User`}</div>
            </Modal.Header>
            <Formik initialValues={user} onSubmit={handleSaveClicked} validationSchema={validationSchema} enableReinitialize={true}>
                {({ errors, touched, values }) => (
                    <FormikForm>
                        <Modal.Body>
                            <Stack gap={3}>
                                <Row>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>First Name</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={touched.FirstName && errors.FirstName } label="First Name" name="FirstName" as={Form.Control}></Field>
                                                    {
                                                        touched.FirstName && errors.FirstName && 
                                                        <small className="text-danger">{ errors.FirstName }</small>
                                                    }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>Last Name</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.LastName } label="Last Name" name="LastName"  as={Form.Control}></Field>
                                                            {
                                                                errors.LastName && 
                                                                <small className="text-danger">{ errors.LastName }</small>
                                                            }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>                                                
                                </Row>
                                <Row>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>Email Address</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.Email } label="Email Address" name="Email"  as={Form.Control}></Field>
                                                    {
                                                        errors.Email &&
                                                        <small className="text-danger">{ errors.Email }</small>
                                                    }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                                        <Stack gap={3}>                                        
                                            <Form.Group as={Row}>
                                                <Col className="col-3 d-flex align-items-center">
                                                    <Form.Label>Role</Form.Label>
                                                </Col>
                                                <Col className="col-9">
                                                    <Field isInvalid={errors.RoleId } label="Role" name="RoleId"  as={Form.Select}>
                                                        <option value={""}>Please select a role</option>
                                                        {
                                                            roles && roles.map((role, index) => {
                                                                return (
                                                                    <option key={index} value={role.Id}>{role.Name}</option>
                                                                )
                                                            })
                                                        }                                                      
                                                    </Field>
                                                    {
                                                        errors.RoleId &&
                                                        <small className="text-danger">{ errors.RoleId }</small>
                                                    }
                                                </Col>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                </Row>                                                    
                            </Stack>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-admin-primary">{saving ? "Saving..." : "Save"}</button>
                            <button type="button" className="btn btn-admin-secondary" onClick={() => modal.onClose(false)}>Cancel</button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
            <AlertModal                    
                show={showAlert}
                onClose={() => {
                    setShowAlert(false);
                    setAlertMessage("");
                    setSuccessMessage("");
                    modal.refresh();
                    modal.onClose(true);
                }}
                message={alertMessage ? alertMessage : successMessage}
                success={successMessage}
            />
        </Modal>
    )
}