import { Dropdown } from "react-bootstrap";
import ProtectedComponent from "../../../Core/ProtectedComponent";

export default function IndexActionsDropwdown({handleRefreshStatus, installComplete, handleInstallComplete, handleEditAddress}) {
    
    return (
        <Dropdown>
            <Dropdown.Toggle className="btn-actions p-1 px-3">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu style={{borderRadius: "20px"}}>
                <ProtectedComponent permission="Address Management.Actions.Refresh Status">
                    <Dropdown.Item style={{fontSize:"11px"}} onClick={handleRefreshStatus}>Refresh Status</Dropdown.Item>
                </ProtectedComponent>
                <ProtectedComponent permission="Address Management.Actions.Edit Address">
                    <Dropdown.Item style={{fontSize:"11px"}} onClick={() => handleEditAddress(true)} disabled={installComplete ? true : false}>Edit Address</Dropdown.Item>
                </ProtectedComponent>
                <ProtectedComponent permission="Address Management.Actions.Install Complete">
                    <Dropdown.Item style={{fontSize:"11px"}} onClick={() => handleInstallComplete(true)} disabled={installComplete ? true : false}>Install Complete</Dropdown.Item>
                </ProtectedComponent>
            </Dropdown.Menu>
        </Dropdown>
    )
}